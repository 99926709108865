import {DecimalPipe} from '@angular/common';
import {Language} from '@application/translation/language/language.enum';
import {TranslateService} from '@application/translation/translate.service';
import {ContactType} from '@domain/customer/contact-type.enum';
import {MessageType} from '@domain/message-centre/message-type.enum';
import {CustomerRelationship} from '@domain/product-catalogue/customer-relationship.enum';
import {ItemCategory} from '@domain/product-catalogue/item-category.enum';
import {ContractCategory} from '@domain/sales/contract/contract-category.enum';
import {OrderSource} from '@domain/sales/order/order-source.enum';
import {QuotationCategory} from '@domain/sales/quotation/quotation-category.enum';
import {Currency} from '@infrastructure/http/custom-setting/master-data/currency';
import {PaymentCondition} from '@infrastructure/http/custom-setting/payment-conditions/payment-condition';
import {Contact} from '@infrastructure/http/customer-relationship/contact';
import {CustomerResponse} from '@infrastructure/http/customer-relationship/customer/customer.response';
import {LocalizedDescription} from '@infrastructure/http/localized-description';
import {LocalizedName} from '@infrastructure/http/localized-name';
import {UserResponse} from '@infrastructure/http/organization/user/user.response';
import {CustomerPriceFormTypeEnum} from '@infrastructure/http/product-catalogue/customer-price-form/customer-price-form-type.enum';
import {SalesItemResponse} from '@infrastructure/http/product-catalogue/pricing/sales-item.response';
import {ProductCatalogueItemResponse} from '@infrastructure/http/product-catalogue/product-catalogue-item.response';
import {ProductResponse} from '@infrastructure/http/product-catalogue/product/product.response';
import {SparePartResponse} from '@infrastructure/http/product-catalogue/spare-part/spare-part.response';
import {InChargeByResponse} from '@infrastructure/http/sales/in-charge-by.response';
import {SalesCustomerResponse} from '@infrastructure/http/sales/sales-customer.response';
import {IdName} from '@infrastructure/id-name';
import {UserCodeName} from '@infrastructure/user-code-name';
import {convertNumber} from '@siakhooi/number-to-chinese-words';
import {isNil} from 'lodash-es';
import {EnumUtils} from './enum/enum-utils';

export class DataFormatter {
  public constructor(private translateService: TranslateService) {}

  public static formatItem(data: ProductResponse | SparePartResponse | ProductCatalogueItemResponse): string {
    const itemName = data.localizedNames.find((n: LocalizedName) => Language[n.language] === Language.ZH)?.name;
    const itemCode = data.code;
    return `${itemName} - ${itemCode}`;
  }

  public static formatItemName(data: SalesItemResponse): string {
    const itemName = data.localizedNames.find((n: LocalizedName) => Language[n.language] === Language.ZH)?.name;
    const itemCode = data.code;
    return `${itemCode} - ${itemName}`;
  }

  public static formatItemNameCode(data: SalesItemResponse): string {
    const itemName = data.localizedNames.find((n: LocalizedName) => Language[n.language] === Language.ZH)?.name;
    return `${data.code} - ${itemName} - ${data.priceFormCode}`;
  }

  public static formatPricingItemName(data: ProductResponse | SparePartResponse | ProductCatalogueItemResponse | SalesItemResponse): string {
    const itemName = data.localizedNames.find((m: any) => m.name);
    const itemCode = data.code;
    return `${itemName.name} - ${itemCode}`;
  }

  public static formatCurrency(currency: Currency): string {
    return `${currency.code} - ${currency.name}`;
  }

  public static formatIndex(index: number, language: Language): string {
    return language === Language.ZH ? convertNumber(index) : index.toString();
  }

  public static formatPrice(currency: Currency, amount: number | string): string {
    return !isNil(currency?.code) ? `${currency.code} ${amount}` : '-';
  }

  public formatQuotationPriceCurrency(decimalPipe: DecimalPipe, price: string): string {
    return decimalPipe.transform(price, '1.2-2');
  }

  public formatPriceCurrency(decimalPipe: DecimalPipe, price: string): string {
    return decimalPipe.transform(price, '1.4-4', 'en-US');
  }

  public formatItemCategory(itemCategory: ItemCategory): string {
    return this.translateService.instant(`PRODUCT_CATALOGUE.PRICING.ITEM_CATEGORY.${ItemCategory[itemCategory]}`);
  }

  public formatPriceCategory(priceCategory: IdName): string {
    let result = this.translateService.instant('GENERAL.NOT_AVAILABLE');
    if (!isNil(priceCategory)) {
      result = `${priceCategory.name ?? result}`;
    }
    return result;
  }

  public formatCompanyName(data: CustomerResponse): string {
    let result = this.translateService.instant('GENERAL.NOT_AVAILABLE');

    if (!isNil(data)) {
      result = `${data.companyName} - ${data.code ?? result}`;
    }

    return result;
  }

  public formatSalesCompanyName(data: SalesCustomerResponse): string {
    let result = this.translateService.instant('GENERAL.NOT_AVAILABLE');

    if (!isNil(data)) {
      result = `${data.companyName} - ${data.code ?? result}`;
    }

    return result;
  }

  public formatInternalCompanyName(data: CustomerResponse): string {
    let result = this.translateService.instant('GENERAL.NOT_AVAILABLE');

    if (!isNil(data)) {
      result = `${data.code ?? result} - ${data.companyName}`;
    }

    return result;
  }

  public formatPaymentConditionDescriotion(data: PaymentCondition, language: Language): string {
    return data.localizedDescriptions.find((n: LocalizedDescription) => Language[n.language] === language)?.description;
  }

  public formatOrderSource(orderSource: OrderSource): string {
    return this.translateService.instant(`SALES.ORDER.ORDER_SOURCE.${OrderSource[orderSource]}`);
  }

  public formatContractCategory(contractCategory: ContractCategory): string {
    return this.translateService.instant(`SALES.CONTRACT.CATEGORY.${ContractCategory[contractCategory]}`);
  }

  public formatQuotationCategory(quotationCategory: QuotationCategory): string {
    return this.translateService.instant(`SALES.QUOTATION.CATEGORY.${QuotationCategory[quotationCategory]}`);
  }

  public formatCustomerRelationship(customerRelationship: CustomerRelationship): string {
    return this.translateService.instant(`PRODUCT_CATALOGUE.PRICING.CUSTOMER_RELATIONSHIP.${CustomerRelationship[customerRelationship]}`);
  }

  public formatMessageType(messageType: MessageType): string {
    return this.translateService.instant(`MESSAGE_CENTRE.BROADCAST_MESSAGE.MESSAGE_TYPE.${MessageType[messageType]}`);
  }

  public formatLocalizedObject(object: string, language: Language): string {
    return `${object} (${this.translateService.instant(`GENERAL.LANGUAGE.${EnumUtils.getKeyFromValue(Language, language)}`)})`;
  }

  public formatPICName(data: UserResponse): string {
    let result = this.translateService.instant('GENERAL.NOT_AVAILABLE');

    if (!isNil(data?.localizedNames)) {
      const name = data.localizedNames.find((n: LocalizedName) => Language[n.language] === Language.ZH)?.name;
      result = `${data.employeeCode ?? result} - ${name}`;
    }

    return result;
  }

  public formatInChargeByName(data: InChargeByResponse): string {
    let result = this.translateService.instant('GENERAL.NOT_AVAILABLE');

    if (!isNil(data)) {
      result = data.code ? `${data.code} - ${data.name}` : result;
    }

    return result;
  }

  public formatGetNameByLanguage(data: UserResponse, language: Language): string {
    return data.localizedNames.find((n: LocalizedName) => Language[n.language] === language)?.name;
  }

  public formatBoolean(bool: boolean): string {
    return this.translateService.instant(`GENERAL.${bool ? 'YES' : 'NO'}`);
  }

  public formatMobileNumber(contacts: Contact[]): string {
    return contacts?.find((contact: Contact) => contact.contactType === ContactType.MOBILE)?.contact;
  }

  public formatApproveByName(data: UserCodeName): string {
    return !isNil(data) ? `${data.code} - ${data.name}` : '-';
  }

  public formatCurrencyCode(currency: Currency): string {
    return this.translateService.instant('SALES.ITEM_DETAIL.TOTAL_PRICE', {
      object: currency ? currency.code : this.translateService.instant('PAYMENT.CURRENCY')
    });
  }

  public formatCustomerPriceFormCurrencyCode(currency: Currency, priceType: CustomerPriceFormTypeEnum): string {
    const singlePriceTitle = this.translateService.instant('PRODUCT_CATALOGUE.CUSTOMER_PRICE_FORM.ITEM_INFORMATION.SINGLE_ACTUAL_PRICE.WITH_CURRENCY_CODE', {
      object: `(${currency.code})`
    });
    const groupPriceType = this.translateService.instant('PRODUCT_CATALOGUE.CUSTOMER_PRICE_FORM.ITEM_INFORMATION.GROUP_ACTUAL_PRICE.WITHOUT_CURRENCY_CODE');

    const title = priceType === CustomerPriceFormTypeEnum.SINGLE_PRICE ? singlePriceTitle : groupPriceType;

    return title;
  }

  public formatTotalPriceCurrencyCode(currency: Currency): string {
    return this.translateService.instant('PRODUCT_CATALOGUE.CUSTOMER_PRICE_FORM.ITEM_INFORMATION.GROUP_ACTUAL_PRICE.WITH_CURRENCY_CODE', {
      object: `(${currency.code})`
    });
  }

  public formatCustomerPriceFormPriceLabel(currency: Currency, priceType: CustomerPriceFormTypeEnum): string {
    const singlePriceTitle = this.translateService.instant('PRODUCT_CATALOGUE.CUSTOMER_PRICE_FORM.TOTAL_PRICE.SINGLE', {
      object: `(${currency.code})`
    });
    const groupPriceType = this.translateService.instant('PRODUCT_CATALOGUE.CUSTOMER_PRICE_FORM.TOTAL_PRICE.GROUP', {object: `(${currency.code})`});

    const title = priceType === CustomerPriceFormTypeEnum.SINGLE_PRICE ? singlePriceTitle : groupPriceType;

    return title;
  }

  public formatDate(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }
}
